import { useStaticQuery, graphql, Link } from "gatsby"
import { IAllPrismicSitebannerQueryResponse } from "./types"
import { BannerContainer, BannerText } from "./styled"
import { usePromoCode } from "./hooks/usePromoCode"
import { DEFAULT_BANNER } from "./constants"
import { useLocation, navigate } from "@reach/router"
import Cookies from "js-cookie"
import { useEffect, useState } from "react"

const PRISMIC_BANNER_QUERY = graphql`
  query SiteBannerQuery {
    allPrismicSitebanner {
      nodes {
        data {
          banner_name
          banner_text {
            text
          }
          banner_link {
            url
          }
        }
      }
    }

    allPrismicPage {
      nodes {
        data {
          banner_text {
            text
          }
          banner_url {
            url
          }
        }
        uid
      }
    }
  }
`

const checkInfluencer = () => {
  const influencerName = Cookies.get("influencer")
  if (influencerName) {
    return (
      <BannerContainer
        id="site-banner"
        onClick={() => navigate(process.env.GATSBY_START_URL)}
      >
        <BannerText>{`Use code ${influencerName}15 for 15% off your first month on all monthly plans`}</BannerText>
      </BannerContainer>
    )
  }
}

export const SiteBanner = () => {
  const [bannerText, setBannerText] = useState<string>()
  const [bannerUrl, setBannerUrl] = useState<string>()
  const influencerBanner = checkInfluencer()

  const promoCode = usePromoCode()
  const { pathname } = useLocation()

  const { allPrismicSitebanner, allPrismicPage } =
    useStaticQuery<IAllPrismicSitebannerQueryResponse>(PRISMIC_BANNER_QUERY)

  useEffect(() => {
    const variant = promoCode ?? DEFAULT_BANNER

    const splitedPathname = pathname.split("/")
    const pageUid = splitedPathname[splitedPathname.length - 1]

    const bannerDocument = allPrismicSitebanner.nodes.find(
      banner => banner.data.banner_name === variant
    )

    const pageBannerData = allPrismicPage.nodes.find(
      page => page.uid === pageUid
    )?.data

    const bannerData = bannerDocument?.data
    setBannerText(
      pageBannerData?.banner_text.text || bannerData?.banner_text.text
    )
    setBannerUrl(
      pageBannerData?.banner_url.url || bannerData?.banner_link.url || "#"
    )
  }, [pathname, promoCode, allPrismicSitebanner, allPrismicPage])

  if (influencerBanner) return influencerBanner
  if (!bannerText) return null

  return (
    <BannerContainer
      id="site-banner"
      onClick={() => (bannerUrl ? navigate(bannerUrl) : null)}
    >
      <BannerText>{bannerText}</BannerText>
    </BannerContainer>
  )
}
